import React from "react";

import { MainLayout } from "../layouts/Main";
import { LegalPageContent } from "../page-content/LegalPage";

export const TermsAndConditionsPage = () => {
  return (
    <MainLayout
      title="Basilium's Terms and Conditions"
      description="Here's an outline of the terms and conditions of using Basilium"
    >
      <LegalPageContent document="terms" />
    </MainLayout>
  );
};

export default TermsAndConditionsPage;
